

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon, IonBackButton,
        IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonInput,
        loadingController, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';

// composables
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

// icons
import { mail, arrowBack, } from 'ionicons/icons';
import { ref } from 'vue';

// services
import AuthService from '@/services/AuthService';

export default {
  name: 'ForgotPasswordPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon, IonBackButton,
                IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonInput,
                LogoImg, },
  setup() {
    const loginEmail = ref("");
    const resetPswEmail = ref("");

    // methods
    const { t } = useI18n();
    const router = useRouter();
    const { presentToast } = utils();

    const resetPassword = async(email: string) => {
      if (email) {
        const loading = await loadingController.create({});
        await loading.present();
        try {
          await AuthService.resetPassword(email);
          resetPswEmail.value = email;
        } catch (e) {
          presentToast(e.message); // wrong email or wrong password
        }
        loading.dismiss();
      } else {
        presentToast(t('ForgotPasswordPage.enterLoginEmail'), 3000);
      }
    };
    const goToLoginPage = () => {
      resetPswEmail.value = "";
      router.replace('/login'); // go to forgot password page
    }
    
    return {
      t,

      // icons
      mail, arrowBack,
      
      // variables
      loginEmail, resetPswEmail,

      // methods
      resetPassword, goToLoginPage,
    }
  },
}
